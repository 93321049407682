import * as React from 'react';

import { Layout } from '../components/Layout';
import SEO from '../components/seo';
import { Container } from '../components/Container';
import styles from './404.module.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <div className={styles.page}>
        <h1>Not found</h1>
        <p>
          Sorry we couldn't find what you are looking for. Use the navigation
          above or below to find what you are looking for.
        </p>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
